<template>
  <!-- 批量操作 -->
  <div class="batch-operation-component">
    <createShop v-bind="$attrs" :sup_this="sup_this" />
  </div>
</template>

<script>
import createShop from '../createShop'
export default {
  components: {
    createShop
  },
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      s_this: this
    }
  }
}
</script>

<style></style>
